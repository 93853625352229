<template>
  <main class="grid">
    <div id="create-admin" class="auth">
      <div class="profile-title">
        <span> Create New Admin </span>
      </div>
      <!-- <h1 class="header">Create Admin</h1> -->
      <form action="" class="auth-form" @submit.prevent="createAdmin">
        <fieldset :disabled="loading">
          <div class="input-group">
            <input
              v-model="displayName"
              type="text"
              placeholder=" "
              id="displayName"
              autocomplete="name"
              required
            />
            <label for="displayName">Name</label>
            <p class="error-info">Please enter your given name</p>
          </div>
          <div class="input-group">
            <input
              v-model="email"
              type="email"
              placeholder=" "
              id="email"
              autocomplete="email"
              required
            />
            <label for="email">Email</label>
            <p class="error-info">Please enter a valid email</p>
          </div>
          <div class="phoneNumber-wrapper">
            <div class="custom-select" @click="dialCodeOpen = !dialCodeOpen">
              <label for="">Code</label>
              <div
                class="active"
                tabindex="0"
                @keydown.enter="dialCodeOpen = true"
                @keydown.esc="dialCodeOpen = false"
              >
                {{ dial_code }}
              </div>
              <ul v-show="dialCodeOpen" class="options">
                <li
                  v-for="option in countries"
                  v-show="option.dial_code !== dial_code"
                  :key="option.code"
                  class="option"
                  tabindex="0"
                  :data-value="option.name"
                  @click="dial_code = option.dial_code"
                  @keydown.enter="
                    (dial_code = option.dial_code), (dialCodeOpen = false)
                  "
                >
                  {{ option.dial_code }} - {{ option.name }}
                </li>
              </ul>
            </div>
            <div class="input-group">
              <input
                v-model="phoneNumber"
                type="tel"
                placeholder=" "
                id="phoneNumber"
                autocomplete="tel"
                required
              />
              <label for="phoneNumber">Phone Number</label>
              <p class="error-info">Please enter a valid phonenumber</p>
            </div>
          </div>
          <div class="input-group">
            <input
              v-model="password"
              type="password"
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$"
              placeholder=" "
              id="password"
              autocomplete="new-password"
              required
            />
            <label for="password">Password</label>
            <p class="error-info">
              Must contain at least one uppercase letter, one lowercase letter
              and one number and be at least 6 characters long
            </p>
          </div>
          <button :class="{ loading }" type="submit">
            <template v-if="!loading">Add An Admin</template>
          </button>
        </fieldset>
      </form>
      <div class="login-signup-now">{{ errorMessage }}</div>
    </div>
    <div class="">
      <AdminsGrid />
    </div>
  </main>
</template>

<script>
import { AdminsGrid } from "@/components";
import countries from "@/data/countries.json";
import { mapActions } from "vuex";

export default {
  name: "CreateAdmin",
  components: {
    AdminsGrid,
  },
  data() {
    return {
      errorMessage: null,
      email: null,
      password: null,
      displayName: null,
      phoneNumber: null,
      loading: false,
      dial_code: "+234",
      dialCodeOpen: false,
      searchedKeys: "",
      timeout: null,
    };
  },
  methods: {
    async createAdmin() {
      this.loading = true;
      this.errorMessage = null;
      try {
        await this.createNewAdmin({
          phoneNumber: this.formattedNumber,
          displayName: this.displayName,
          email: this.email,
          password: this.password,
          admin: true,
        });
        this.reset();
      } catch (error) {
        this.errorMessage = error.message;
      } finally {
        this.loading = false;
      }
    },
    handleClick(event) {
      this.searchedKeys += event.key;
      const element = Array.from(this.countriesHTMLElements).find((element) =>
        // element.dataset.value.includes(this.searchedKeys)
        element.dataset.value
          .toLowerCase()
          .startsWith(
            this.searchedKeys.slice(
              0,
              Math.max(element.dataset.value.length - 1, 1)
            )
          )
      );
      if (this.dialCodeOpen) element?.focus();
    },
    reset() {
      const data = {
        errorMessage: null,
        email: null,
        password: null,
        displayName: null,
        phoneNumber: null,
        loading: false,
        dial_code: "+234",
        dialCodeOpen: false,
        searchedKeys: "",
        timeout: null,
      };

      for (const key in data) {
        this.$data[key] = data[key];
      }
    },
    ...mapActions(["updateUser", "createNewAdmin"]),
  },
  watch: {
    dialCodeOpen(newValue) {
      if (newValue) {
        this.countriesHTMLElements[0].focus();
        document.addEventListener("keydown", this.handleClick);
      } else {
        document.removeEventListener("keydown", this.handleClick);
      }
    },
    searchedKeys() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.searchedKeys = "";
      }, 500);
    },
  },
  computed: {
    countries() {
      return countries;
    },
    formattedNumber() {
      return (
        this.dial_code +
        (this.phoneNumber?.charAt(0) === "0"
          ? this.phoneNumber?.substring(1)
          : this.phoneNumber)
      );
    },
    countriesHTMLElements() {
      return this.$el.querySelectorAll(".custom-select .option");
    },
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleClick);
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;

  @media (min-width: 800px) {
    grid-template-columns: 1.5fr 1fr;
  }
}

#create-admin {
  color: white;
  height: 100%;
  width: Min(400px, 100%);
  margin-left: auto;
  margin-right: auto;

  .forgot-password {
    color: white;
    font-size: 1.3rem;
  }
}

.header {
  font-size: 3.2rem;
  font-weight: 700;
  margin-bottom: 28px;
}

.phoneNumber-wrapper {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto 1fr;
  position: relative;
  margin-bottom: 15px;
  grid-template-rows: 50px;

  .input-wrapper {
    margin-bottom: 0;
  }

  .custom-select {
    overflow: hidden;
    background: $input-color;
    border-radius: 10px;
    padding: 10px;
    height: 50px;
    font-size: 1.2rem;
    /* display: grid;
    place-items: center; */
    padding: 20px 16px 0;

    .active {
      font-size: 1.6rem;
      cursor: pointer;
    }

    label {
      left: 16px;
      position: absolute;
      font-size: 1.1rem;
      top: 7px;
      color: #8c8c8c;
    }

    .options {
      list-style: none;
      border-radius: 10px;
      height: 250px;
      overflow-y: auto;
      background: black;
      scrollbar-width: none;
      position: absolute;
      left: 0;
      top: 53px;
      z-index: 10;

      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
        display: none;
      }

      .option {
        padding: 10px;
        cursor: pointer;
        transition: 0.2s all ease;

        &:hover,
        &:focus {
          background: cadetblue;
          outline: none;
        }
      }
    }
  }
}
</style>
